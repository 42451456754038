import { AppStateType } from './types';

export const appInitState: AppStateType = {
  error: null,

  loaded: false,
  loading: false,
  synchronizing: false,
  playing: false,
  offline: false,
  appOut: false,
  needSync: false,

  playlist: undefined,
  branch: undefined,
  syncApp: undefined,
  appStatus: undefined,

  allVideoList: [],
  videoPlaylist: [],
  files: [],

  logs: [],
  uploadLogs: [],
}

export function createInitialState(appState: AppStateType): AppStateType {
  return appState;
}