import { useState, useCallback, useEffect, useReducer } from 'react';
import type { BranchEntity, SyncAppEntity, VideoPlaylistEntity } from '@guardianslab/types';
import { Dialog, CircularProgress, Backdrop, Container, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { indexDBId } from '../../environments';
import { AppStatusActionDefinitions } from '../../actions/app';
import { createDeleteDirectoryHandlerFromIndexDB } from '../../utils/indexDB';
import { type AppStateType, type AppReducerTypes } from '../../reducers/app';
import type { AppVideoFileType } from '../../types';
import {
  appStateReducer,
  appInitState,
  createInitialState,
  useAppSelector,
 } from '../../reducers/app';
import { Loader, ShowError } from '../common';
import { AppController } from '../app-controller';
import { VideoPlayController } from '../video';
import { Branch } from '../branch';
import { SyncApp } from '../sync';
import { Playlist } from '../playlist';
import {
  createAppScheduler,
  createSyncronizer,
  type AppProviderClass,
  type AppScheduler,
  type FileSyncronizer,
} from '../../provider';

type HomeProps = {
  appProvider: AppProviderClass;
  fileSystemDirectoryHandle: FileSystemDirectoryHandle;
  videoFiles: File[];
  database: IDBDatabase;
}
export default function AppHome(props: HomeProps) {
  const {
    appProvider,
    fileSystemDirectoryHandle,
    videoFiles,
    database,
  } = props;

  const navigate = useNavigate();

  const [fileSyncronizer] = useState<FileSyncronizer>(createSyncronizer(appProvider, fileSystemDirectoryHandle));
  const [scheduler] = useState<AppScheduler>(createAppScheduler());
  
  const [appState, dispatchApp] = useReducer<AppReducerTypes, AppStateType>(
    appStateReducer,
    {
      ...appInitState,
      files: videoFiles,
    },
    createInitialState,
  );
  const appSelector = useAppSelector(appState);

  const loaded = appSelector('loaded') as boolean;
  const branch = appSelector('branch') as BranchEntity;
  const syncApp = appSelector('syncApp') as SyncAppEntity;
  const uploadLogs = appSelector('uploadLogs') as string[];
  const loading = appSelector('loading') as boolean;
  const appOut = appSelector('appOut') as boolean;
  // const needSync = appSelector('needSync') as boolean;
  const synchronizing = appSelector('synchronizing') as boolean;
  const playing = appSelector('playing') as boolean;
  const playlist = appSelector('playlist') as VideoPlaylistEntity;
  const error = appSelector('error') as Error;

  const allVideoFiles = appSelector('allVideoList') as AppVideoFileType[];
  const videoPlaylistFiles = appSelector('videoPlaylist') as AppVideoFileType[];

  const handleAddLog = useCallback((log: any) => {
    dispatchApp({
      type: AppStatusActionDefinitions.TYPE_ADD_LOG,
      value: log,
    });
  }, []);

  const handleStartVideoPlay = useCallback(() => {
    dispatchApp({
      type: AppStatusActionDefinitions.TYPE_PLAYING,
      value: true,
    });
  }, []);

  const handleStopVideoPlay = useCallback(() => {
    dispatchApp({
      type: AppStatusActionDefinitions.TYPE_PLAYING,
      value: false,
    });
  }, []);

  const handleAppOut = useCallback(async () => {
    dispatchApp({
      type: AppStatusActionDefinitions.TYPE_APPOUT,
      value: true,
    });
    const deleteKey = createDeleteDirectoryHandlerFromIndexDB(database, indexDBId);
    await deleteKey();
    await appProvider.unRegisterApp();
    
    dispatchApp({
      type: AppStatusActionDefinitions.TYPE_APPOUT,
      value: false,
    });
    navigate('/login', { replace: true });
  }, [appProvider, database]);

  const initialize = useCallback(async () => {
    const appStatus = appProvider.getAppState();
    const { playlist, branch, syncApp } = appProvider.getAppInfoState();

    dispatchApp({
      type: AppStatusActionDefinitions.TYPE_LOADING,
      value: {
        loading: true,
      },
    });
    
    dispatchApp({
      type: AppStatusActionDefinitions.TYPE_LOADED,
      value: {
        loaded: true,
        appStatus,
        playlist,
        branch,
        syncApp,
      },
    });
  }, [appProvider]);

  const handleSync = useCallback(async () => {
    dispatchApp({
      type: AppStatusActionDefinitions.TYPE_SYNCHRONIZING,
      value: true,
    });

    const { success, data } = await fileSyncronizer.syncronize();
    if (success) {
      const { files, playlist } = data || {};
      dispatchApp({
        type: AppStatusActionDefinitions.TYPE_SET_VIDEOFILES,
        value: {
          playlist,
          files,
        },
      });
    }
  
    dispatchApp({
      type: AppStatusActionDefinitions.TYPE_SYNCHRONIZING,
      value: false,
    });
  }, [fileSyncronizer]);

  useEffect(() => {
    if (loaded === false && loading === false) {
      initialize();
    }
  }, [initialize, loaded, loading]);

  useEffect(() => {
    if (uploadLogs.length) {
      appProvider.writeVideoLogAndUpload(uploadLogs)
      .then(() => {
        dispatchApp({
          type: AppStatusActionDefinitions.TYPE_CLEAR_UPLOAD_LOG,
        });
      });
    }
  }, [uploadLogs]);

  // set event to scheduler
  useEffect(() => {
    scheduler.setEvent({
      playStart: handleStartVideoPlay,
      playStop: handleStopVideoPlay,
      synchronize: handleSync,
    });
  }, [scheduler, handleStartVideoPlay, handleStopVideoPlay, handleSync]);

  if (loaded === false) {
    return <Loader />;
  } else if (error) {
    return <ShowError error={error} resetError={() => {
      dispatchApp({
        type: AppStatusActionDefinitions.TYPE_SET_ERROR,
        value: null,
      });
    }}/>
  }

  return (
    <Container>
      <Box>
        <AppController
          needSync={true}
          canPlay={videoPlaylistFiles && videoPlaylistFiles.length > 0}
          handleAppOut={handleAppOut}
          handlePlay={handleStartVideoPlay}
          handleSync={handleSync}
        />

        {
          branch && 
          <Branch branch={branch} />
        }

        {
          syncApp && 
          <SyncApp syncApp={syncApp} />
        }

        {
          playlist && 
          <Playlist
            playlist={playlist}
            videoFiles={allVideoFiles}
          />
        }

        { appOut && 
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={appOut}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        }

        { playing &&
          <Dialog
            fullScreen
            sx={{
              backgroundColor: '#000',
              zIndex: (theme) => theme.zIndex.drawer + 1
            }}
            onDoubleClick={handleStopVideoPlay}
            open={playing}
          >
            <VideoPlayController
              videoFiles={videoPlaylistFiles}
              handleAddLog={handleAddLog}
            />
          </Dialog>
        }

        {
          synchronizing && 
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={synchronizing}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        }

      </Box>
    </Container>
  );
}

