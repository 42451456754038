export function getStorage(): Storage {
  return window.localStorage;
}

export function getDataFromStorage(key: string): string | object | undefined {
  if (key === 'token') {
    return getStorage().getItem(key) || undefined;
  } else if (key === 'appStatus') {
    const data = getStorage().getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return undefined;
  } else if (key === 'playlist') {
    const data = getStorage().getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return undefined;
  } else if (key === 'syncApp') {
    const data = getStorage().getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return undefined;
  } else if (key === 'branch') {
    const data = getStorage().getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return undefined;
  }
}

export function saveDataToStorage(key: string, data: string | object): void {
  if (typeof data === 'string') {
    getStorage().setItem(key, data);
  } else if (typeof data === 'object') {
    getStorage().setItem(key, JSON.stringify(data));
  }
}

export function removeDataFromStorage(key: string): void {
  getStorage().removeItem(key);
}