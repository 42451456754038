import { useEffect } from 'react';

import AppHome from './AppHome';
import { Loader } from '../common';
import {
  useAppProvider,
  useFileSystemDirectoryHandle,
  useGetLocalVideoFiles,
  useGetIndexDB,
} from '../../hooks';

export default function PrepareAppHome() {
  const appProvider = useAppProvider();
  // const scheduler = useAppScheduler();
  const fileSystemDirectoryHandle = useFileSystemDirectoryHandle();
  const database = useGetIndexDB();
  const [filesFetched, files, error] = useGetLocalVideoFiles({
    appProvider,
    fileSystemDirectoryHandle: fileSystemDirectoryHandle
  });

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  if (appProvider && filesFetched && fileSystemDirectoryHandle && database) {
    appProvider.setFileSystemDirectoryHandle(fileSystemDirectoryHandle);
    return (
      <AppHome
        appProvider={appProvider}
        fileSystemDirectoryHandle={fileSystemDirectoryHandle}
        videoFiles={files}
        database={database}
      />
    );
  }

  return <Loader />;
}