import { AxiosError } from 'axios';
import { AppStatusType, BranchEntity, SyncAppEntity, VideoPlaylistEntity } from '@guardianslab/types';

import { createDataProvider, DataProvider } from '../data';
import {
  broswerDirectory,
  writeVideoFile,
  removeFile,
  getVideoFiles,
  createVideoFileName,
  getCreateFileHandle,
 } from '../../utils/file-systems';
import type { LoginOptions } from './types';
import { removeDataFromStorage, getDataFromStorage, saveDataToStorage } from '../localStorage';
import { AppFileHandle } from '../fileHandle';
import { AppLog } from '../log';

  export class AppProviderClass {
    private readonly dataProvider: DataProvider;
    private appFileHandle: AppFileHandle | undefined = undefined;
    private fileSystemDirectoryHandle: FileSystemDirectoryHandle | undefined = undefined;
    private token: string | undefined = undefined;

    private authenticated: boolean = false;
    private appState: AppStatusType | undefined = undefined;
    private playlist: VideoPlaylistEntity | undefined = undefined;
    private syncApp: SyncAppEntity | undefined = undefined;
    private branch: BranchEntity | undefined = undefined;

    private isVideoLogUploading = false;
    
    constructor(dataProvider: DataProvider) {
      this.dataProvider = dataProvider;

      this.token = getDataFromStorage('token') as string;
      this.appState = getDataFromStorage('appStatus') as AppStatusType;
      this.playlist = getDataFromStorage('playlist') as VideoPlaylistEntity;
      this.syncApp = getDataFromStorage('syncApp') as SyncAppEntity;
      this.branch = getDataFromStorage('branch') as BranchEntity;
    }
  
    private async checkAuthentiateApp(token: string) {
      // TODO 추후 Offline일 경우 어떻게 할지 고민!!
      try {
        const data = await this.dataProvider.checkApp(token);
        this.setAppState(data);
        
        return true;
      } catch(error: unknown) {
        if (error instanceof AxiosError) {
          if (error.code === 'ERR_NETWORK') {
            const appState = this.getAppState();
            if (appState) {
              this.setAppState(appState);
              return true;
            }
          } else if (error.response?.status !== 200) {
            this.setAppState(undefined);
          }
        }
        return false;
      }
    }

    private async getSyncApp(syncAppId: number): Promise<SyncAppEntity | undefined> {
      try {
        const syncApp = await this.dataProvider.getSyncApp(this.token || '', syncAppId);
        this.setSyncApp(syncApp)
        return syncApp;
      } catch (error: unknown) {
        return this.syncApp;
      }
    }

    private async getPlaylist(playlistId: number): Promise<VideoPlaylistEntity | undefined> {
      try {
        const playlist = await this.dataProvider.getPlaylist(this.token || '', playlistId);
        this.setAppPlaylist(playlist);
        return playlist;
      } catch (error: unknown) {
        return this.playlist;
      }
    }

    private async getBranch(branchId: number): Promise<BranchEntity | undefined> {
      try {
        const branch = await this.dataProvider.getBranch(this.token || '', branchId);
        this.setBranch(branch);
        return branch;
      } catch (error: unknown) {
        return this.branch;
      }
    }
  
    private setAuthenticated(authenticated: boolean) {
      this.authenticated = authenticated;
      if (authenticated === false) {
        removeDataFromStorage('token');
      }
    }
  
    private setAppState(data: AppStatusType | undefined) {
      this.appState = data;
      if (data) {
        this.setAuthenticated(data.active);
        saveDataToStorage('appStatus', data);
      } else {
        this.setAuthenticated(false);
        removeDataFromStorage('appStatus');
      }
    }

    private setAppPlaylist(playlist: VideoPlaylistEntity | undefined) {
      if (playlist) {
        this.playlist = playlist;
        saveDataToStorage('playlist', playlist);
      } else {
        this.playlist = undefined;
        removeDataFromStorage('playlist');
      }
    }

    private setSyncApp(syncApp: SyncAppEntity | undefined) {
      if (syncApp) {
        this.syncApp = syncApp;
        saveDataToStorage('syncApp', syncApp);
      } else {
        this.syncApp = undefined;
        removeDataFromStorage('syncApp');
      }
    }

    private setBranch(branch: BranchEntity | undefined) {
      if (branch) {
        this.branch = branch;
        saveDataToStorage('branch', branch);
      } else {
        this.branch = undefined;
        removeDataFromStorage('branch');
      }
    }
  
    private getToken() {
      return this.token;
    }
  
    private saveToken(token: string): void {
      saveDataToStorage('token', token);
    }
   
    private async reset() {
      this.setAppState(undefined);
      this.setAppPlaylist(undefined);

      if (this.fileSystemDirectoryHandle) {
        const localFiles = await this.getLocalVideoFiles(this.fileSystemDirectoryHandle);
        await this.removeAllFiles(this.fileSystemDirectoryHandle, localFiles);
      }
    }

    getAppState(): AppStatusType | undefined {
      return this.appState;
    }

    getAppInfoState(): {
      playlist: VideoPlaylistEntity | undefined;
      syncApp: SyncAppEntity | undefined;
      branch: BranchEntity | undefined;
    } {
      return {
        playlist: this.playlist,
        syncApp: this.syncApp,
        branch: this.branch,
      }
    }

    async getAppInfo(): Promise<{
      playlist: VideoPlaylistEntity | undefined;
      syncApp: SyncAppEntity | undefined;
      branch: BranchEntity | undefined;
    }> {
      const token = this.getToken();
      if (token) {
        const data = await this.dataProvider.checkApp(token);
        if (data?.playlistId) {
          this.playlist = await this.getPlaylist(data.playlistId);
        }
        if (data?.syncAppId) {
          this.syncApp = await this.getSyncApp(data.syncAppId);
        }
        if (data?.branchId) {
          this.branch = await this.getBranch(data.branchId);
        }
        
        return {
          playlist: this.playlist,
          syncApp: this.syncApp,
          branch: this.branch,
        }
      }
      
      return {
        playlist: undefined,
        syncApp: undefined,
        branch: undefined,
      }
    }

    async setFileSystemDirectoryHandle(fileSystemDirectoryHandle: FileSystemDirectoryHandle) {
      this.fileSystemDirectoryHandle = fileSystemDirectoryHandle;
      this.appFileHandle = new AppFileHandle(fileSystemDirectoryHandle);
    }

    async doAuthenticate() {
      const token = this.getToken();
      if (token) {
        await this.checkAuthentiateApp(token);
      }
    }

    async uploadLogFiles(logFiles: File[]) {
      try {
        const token = this.getToken();
        await logFiles.reduce(async (acc, file) => {
          return acc.then(async () => {
            if (token) {
              await this.dataProvider.s3FileUpload(token, 'presigned-video-log', file);
              await this.appFileHandle?.removeVideoLogFile(file.name);
            }
          })
        }, Promise.resolve());
      } catch (error: unknown) {
        console.log(error);
        // AppLog.addError(`[writeVideoLogAndUpload] - ${JSON.stringify(error)}`);
      }
      
    }

    async writeVideoLogAndUpload(logs: any[]): Promise<void> {
      try {
        if (this.isVideoLogUploading === false) {
          this.isVideoLogUploading = true;
          const file = await this.appFileHandle?.writeVideoLog(logs);
          if (file) {
            const token = this.getToken();
            if (token) {
              await this.dataProvider.s3FileUpload(token, 'presigned-video-log', file);
              await this.appFileHandle?.removeVideoLogFile(file.name);
            }
          }
          this.isVideoLogUploading = false;
        }
      } catch (error: unknown) {
        AppLog.addError(`[writeVideoLogAndUpload] - ${JSON.stringify(error)}`);
      }
    }

    async getDownloadVideos(fileSystemDirectoryHandle: FileSystemDirectoryHandle): Promise<void> {
      const token = this.getToken();
      if (token) {
        const downloadUrls = await this.dataProvider.getDownloadVideoUrls(token);

        const downloadAndWrite = downloadUrls.map(async (download) => {
          const videoFileHandle = await getCreateFileHandle(fileSystemDirectoryHandle, createVideoFileName(download.id, download.title, download.type));
          await writeVideoFile(videoFileHandle, this.dataProvider.fileDownload(download.url));
        });
        await Promise.all(downloadAndWrite);
      }
    }

    async getDownloadVideoId(fileSystemDirectoryHandle: FileSystemDirectoryHandle, videoId: number | string): Promise<void> {
      const token = this.getToken();
      if (token) {
        const { id, url, title, type } = await this.dataProvider.getDownloadVideoIdUrl(token, videoId);

        const videoFileHandle = await getCreateFileHandle(fileSystemDirectoryHandle, createVideoFileName(id, title, type));
        await writeVideoFile(videoFileHandle, this.dataProvider.fileDownload(url));
      }
    }

    async removeAllFiles(fileSystemDirectoryHandle: FileSystemDirectoryHandle, files: File[]): Promise<void> {
      await Promise.all(files.map(async (file) => {
        await removeFile(fileSystemDirectoryHandle, file.name);
      }));
    }

    async getLocalVideoFiles(fileSystemDirectoryHandle: FileSystemDirectoryHandle): Promise<File[]> {
      const directory = await broswerDirectory(fileSystemDirectoryHandle);
      const videoFiles = await getVideoFiles(directory);
      return videoFiles;
    }
  
    isAuthenticated(): boolean {
      return this.authenticated;
    }
  
    async login(data: LoginOptions): Promise<void> {
      const token = await this.dataProvider.chcekPasswordAndGetToken(data);
      const auth = window.btoa(`${data.appId}:${token}`);
      this.saveToken(auth);
      await this.doAuthenticate();
    }
  
    unRegisterApp() {
      this.reset();
    }

    async updateAppSync(syncAppId: number, version: number): Promise<void> {
      return await this.dataProvider.updateAppSync(this.token || '', syncAppId, version);
    }
  }
  
  const createAppProvider = (apiUrl: string): AppProviderClass => {
    const dataProvider = createDataProvider(apiUrl);
    const instance = new AppProviderClass(dataProvider);
    return instance;
  };
  
  export default createAppProvider;
  
  