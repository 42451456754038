  import { AppProviderClass } from '../app';
import type { AppVideoFileType } from '../../types';
import { LocalVideoFileStatus } from '../../constants';
import {
  broswerDirectory,
  getVideoFiles,
  getVideoLogFiles,
  removeFile,
 } from '../../utils/file-systems';
import { getVideoFileList } from '../../reducers/app/utils'; 
import { SyncAppEntity, VideoPlaylistEntity } from '@guardianslab/types';

export default class FileSyncronizer {
  private static instance: FileSyncronizer | null = null;
  
  private constructor(private appProvider: AppProviderClass, private fileSystemDirectoryHandle: FileSystemDirectoryHandle) {}

  public static getInstance(appProvider: AppProviderClass, fileSystemDirectoryHandle: FileSystemDirectoryHandle): FileSyncronizer {
    if (this.instance === null) {
      this.instance = new FileSyncronizer(appProvider, fileSystemDirectoryHandle);
    }
    return this.instance;
  }

  private filterUnRegistredVideoFiles(videoFiles: AppVideoFileType[]) {
    const unRegistredVideoFiles = videoFiles.filter((video) => video.flag === LocalVideoFileStatus.unregistered);
    return unRegistredVideoFiles;
  }

  private async getLocalVideoFiles() {
    const directory = await broswerDirectory(this.fileSystemDirectoryHandle);
    const videoFiles = await getVideoFiles(directory);
    return videoFiles;
  }

  private async getLocalVideoLogFiles() {
    const directory = await broswerDirectory(this.fileSystemDirectoryHandle);
    const logFiles = await getVideoLogFiles(directory);
    return logFiles;
  }

  private async removeVideoFiles(videoFiles: AppVideoFileType[]): Promise<void> {
    await Promise.all(videoFiles.map(async (videoFile) => {
      const { file } = videoFile;
      if (file) {
        await removeFile(this.fileSystemDirectoryHandle, file.name);
      }
    }));
  }

  private async uplodLogFiles() {
    const logFiles = await this.getLocalVideoLogFiles();
    await this.appProvider.uploadLogFiles(logFiles)
  }

  private async checkNeedSync({ syncApp, playlist }: { syncApp: SyncAppEntity | undefined, playlist: VideoPlaylistEntity| undefined }): Promise<boolean> {
    /*
      needSync는 2가지 관점을 생각하고 그 관점에 대해 개선해야 한다.
      1. Sync를 해야할 경우
        - 서버에서 업데이트가 이뤄진 경우
      2. Local 파일에 문제가 생긴 경우
    */
    let needSync = false;

    if (syncApp && syncApp.sync === false) {
      console.log('[checkNeedSync]: !!!! syncApp에 의해서 !!!!');
      needSync = true;
    }
    
    if (playlist) {
      const localVideoFiles = await this.getLocalVideoFiles();
      const videoFilesWithPlaylistVideo = getVideoFileList(playlist.video?.slice() || [], localVideoFiles);

      const unRegistredVideoFiles = this.filterUnRegistredVideoFiles(videoFilesWithPlaylistVideo);
      if (unRegistredVideoFiles.length > 0) {
        console.log('[checkNeedSync]: !!!! 등록되지 않은 파일이 있음(누가 넣어놨음) !!!!');
        needSync = true;
      }

      const unDownloadedVideoFiels = videoFilesWithPlaylistVideo.filter((video) => video.flag === LocalVideoFileStatus.undownloaded && video.videoId);
      if (unDownloadedVideoFiels.length) {
        console.log('[checkNeedSync]: !!!! 다운로드 되지 않은 파일이 있음 !!!!');
        needSync = true;
      }
    }

    return needSync;
  }

  private async syncronizeVideo(playlist: VideoPlaylistEntity) {
    const localVideoFiles = await this.getLocalVideoFiles();
    const videoFilesWithPlaylistVideo = getVideoFileList(playlist.video?.slice() || [], localVideoFiles);

    // remove unRegistred Video Files
    const unRegistredVideoFiles = this.filterUnRegistredVideoFiles(videoFilesWithPlaylistVideo);
    if (unRegistredVideoFiles.length > 0) {
      await this.removeVideoFiles(unRegistredVideoFiles);
    }

    // download Files
    const unDownloadedVideoFiels = videoFilesWithPlaylistVideo.filter((video) => video.flag === LocalVideoFileStatus.undownloaded && video.videoId);
    if (unDownloadedVideoFiels.length) {
      await unDownloadedVideoFiels.reduce(async (acc, current) => {
        return acc.then(() => {
          if (current && current.videoId) {
            return this.appProvider.getDownloadVideoId(this.fileSystemDirectoryHandle, current.videoId);
          }  
        })
      }, Promise.resolve());
    }
  }

  public async syncronize() {
    try {
      // 1. 업로드 로그 파일
      await this.uplodLogFiles();

      // 2. (TODO) 업로드 App 로그 파일
      
      // 3. Syncronize
      const { playlist, syncApp } = await this.appProvider.getAppInfo();

      const needSync = await this.checkNeedSync({ syncApp, playlist });

      if (needSync && playlist) {
        await this.syncronizeVideo(playlist);
        if (syncApp && syncApp.sync === false) {
          const { id, version } = syncApp;
          await this.appProvider.updateAppSync(id, version);
        }
      }

      return {
        success: true,
        data: {
          playlist,
          files: await this.getLocalVideoFiles(),
        }
      }
    } catch (error: unknown) {
      console.error(`[syncronize]: ${JSON.stringify(error)}`);
      return {
        success: false,
      }
    }
  }
}